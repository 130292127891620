@import 'fonts.css';

/*
*
* ERGO ELEMENTS - Modification
*
 */
.ee_form-row__descriptor {
  font-size: 100%;
  font-weight: bolder;
}

.ee_form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.ee_panel {
  padding: 5px 15px !important;
  margin-top: 3rem;
}

h2.esc_h4 {
  line-height: 1 !important;
  margin: .8rem 0 .5rem !important;
}

h2.esc_h3 {
    margin: .8rem 0 .5rem !important;
}

.esc_simple-table th {
  text-align: left !important;
}

.esc_simple-table td {
  text-align: left !important;
  vertical-align: top !important;
}

.ee_radio-tile {
  width: 90% !important;
}

.ee_radio-tile__wrapper {
  padding-top: 10px;
}

.ee_radio-tile__content {
  text-align: left !important;
  padding: 1.5rem !important;
}

.ee_radio-tile__check-mark {
  fill: transparent;
}

/*
*
* Allgemein
*
 */
.capitalize {
  text-transform: capitalize;
}

.position-relative {
  position: relative;
}

/*
*
* Components
*
 */
.technische-fehler {
  background-color: #e4d6e4;
  color: #71022e;
  margin-left: 1.5rem;
  margin-top: 10rem !important;
}

.technische-fehlerdetails {
  padding-bottom: 10px;
  margin-top: 10px;
}

.technische-fehlerdetails-content {
  /*padding-bottom: 10px;*/
  padding-top: 50px;
}

.technische-fehlerdetails > .ee_button {
  color: #71022e !important;
  float: right;
  padding-bottom: 15px;
}

.angebot-laden {
  color: #71022e;
  margin-left: 1.5rem;
  margin-top: 10rem !important;
}

.car-icon {
  float: left;
  margin-top: -2px;
  margin-right: 20px;
  fill: #71022e;
}

.sidebar {
  border-left: solid 1px lightgray;
  height: 100%;
  width: 20%;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
}

.sidebar-scroll {
  overflow-y: scroll;
  height: calc(100vh - 200px);
}

.personen-block .rolle > .ee_button {
  margin-bottom: .5rem;
}

.personen-block .rolle > .ee_button.ee_button--primary {
  background: #71022e;
  border-color: #71022e;
  color: #ffffff;
}
.personen-block .rolle > .ee_button.ee_button--primary:disabled {
  background: #71022e !important;
  border-color: #71022e !important;
  color: #ffffff !important;
}

.personen-block-delete > .ee_button {
  padding: 0 !important;
  width: 36px !important;
  margin-top: 4px !important;
}

.angebotsgrund .ee_form-row__item > .ee_form-row__item-container {
  max-width: 100% !important;
}

.angebotsgrund-inner-group {
  margin-bottom: 0 !important;
}

.angebotsgrund-inner-group > .ee_form-row__item {
  margin-bottom: 0 !important;
  flex-basis: 100rem !important;
}

.angebotsgrund-inner-group .ee_form-row__container--horizontal > .ee_form-row__item {
  margin-bottom: 0 !important;
  flex-basis: 100rem !important;
}

.sidebar-scroll > .ee_expand-animation.ee_expand-animation--open {
    height: fit-content !important;
}

.personensuche-personenanzeige {
  margin-top: 20px;
}

.personensuche-personenanzeige > .ee_button {
  border-radius: 8px !important;
  border-width: 0px !important;
  width: 92%;
  align-items: start !important;
  justify-content: start !important;
  text-align: left !important;
  padding: 12px 8px !important;
  font-weight: normal !important;
}

.personensuche-personenanzeige .ee_button > .ee_button__wrapper {
  width: 100% !important;
}

.fahrzeugsuche-ergebnis {
  width: 300px;
  overflow-x: auto;
  overflow-y: hidden;
}

.component-content {
  padding: 20px 15px;
}

.cssTausender {
  position: relative;
  top: -60px;
  left: 19%;
  color: grey;
}

.checkbox-distance {
    margin-bottom: -24px;
}

.ergo-logo {
  width: 8rem;
  padding: 10px 0px;
}

.bankverbindung > .ee_form-row__messages.ee_form-row__messages--wide {
  padding-left: 0 !important;
}

.personensuche > .ee_form-row__messages.ee_form-row__messages--wide {
  padding-left: 0 !important;
}

.fahrzeugnutzung > .ee_form-row__messages.ee_form-row__messages--wide {
  padding-left: 0 !important;
}

.versicherungsschutz {
  position: relative;
}

.versicherungsschutz > .ee_loading-spinner {
  height: 102% !important;
  margin-left: -20px !important;
}

.abschluss-hinweis .ee_checkbox__label {
  font-weight: bold !important;
  padding-top: 0.05em !important;
  padding-bottom: 0.05em !important;
}

.abschluss-hinweis .ee_checkbox__box {
  align-self: flex-start;
}

.antrag-pruefen {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.antrag-pruefen .ee_form-section__heading .ee_form-section__heading-text {
    margin: auto !important;
}

.progress-circle__green {
  max-width: 15%;
  margin: auto;
}

.progress-circle__green > .ee_progress-circle--finish > .ee_progress-circle__mask {
  stroke: #b3c847 !important;
}

.progress-circle__red {
  max-width: 15%;
  margin: auto;
}

.progress-circle__red > .ee_progress-circle--finish > .ee_progress-circle__mask {
  stroke: #ba172d !important;
}

::placeholder {
  color: lightgrey;
  opacity: 1;
}

.ee_form-row__messages.ee_form-row__messages--wide {
  padding-left: 0 !important;
  padding-right: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance:  none;
  margin: 0;
}

.margin-0{
  margin : 0px;
}

.margin-bottom-15{
  margin-bottom : 15px;
}