@font-face {
    font-family: FS Me;
    font-weight: 300;
    src: url(fonts/fsme/FSMeWeb-Light.woff) format("woff")
}

@font-face {
    font-family: FS Me;
    font-weight: 300;
    font-style: italic;
    src: url(fonts/fsme/FSMeWeb-LightItalic.woff) format("woff")
}

@font-face {
    font-family: FS Me;
    font-weight: 500;
    src: url(fonts/fsme/FSMeWeb-Regular.woff) format("woff")
}

@font-face {
    font-family: FS Me;
    font-weight: 500;
    font-style: italic;
    src: url(fonts/fsme/FSMeWeb-Italic.woff) format("woff")
}

@font-face {
    font-family: FS Me;
    font-weight: 700;
    src: url(fonts/fsme/FSMeWeb-Bold.woff) format("woff")
}

@font-face {
    font-family: FS Me;
    font-weight: 700;
    font-style: italic;
    src: url(fonts/fsme/FSMeWeb-BoldItalic.woff) format("woff")
}

@font-face {
    font-family: FS Me;
    font-weight: 900;
    src: url(fonts/fsme/FSMeWeb-Heavy.woff) format("woff")
}

@font-face {
    font-family: FS Me;
    font-weight: 900;
    font-style: italic;
    src: url(fonts/fsme/FSMeWeb-HeavyItalic.woff) format("woff")
}

@font-face {
    font-family: Fedra Serif;
    font-weight: 400;
    src: url(fonts/fedra_serif/WF-006389-009281-000003.woff) format("woff")
}

@font-face {
    font-family: Fedra Serif;
    font-weight: 400;
    font-style: italic;
    src: url(fonts/fedra_serif/WF-006389-009281-000003.woff) format("woff")
}

@font-face {
    font-family: Fedra Serif;
    font-weight: 500;
    src: url(fonts/fedra_serif/WF-006389-009281-000046.woff) format("woff")
}

@font-face {
    font-family: Fedra Serif;
    font-weight: 500;
    font-style: italic;
    src: url(fonts/fedra_serif/WF-006389-009281-000047.woff) format("woff")
}

@font-face {
    font-family: Fedra Serif;
    font-weight: 600;
    src: url(fonts/fedra_serif/WF-006389-009281-000050.woff) format("woff")
}

@font-face {
    font-family: Fedra Serif;
    font-weight: 600;
    font-style: italic;
    src: url(fonts/fedra_serif/WF-006389-009281-000051.woff) format("woff")
}

@font-face {
    font-family: Fedra Serif;
    font-weight: 700;
    src: url(fonts/fedra_serif/WF-006389-009281-000054.woff) format("woff")
}

@font-face {
    font-family: Fedra Serif;
    font-weight: 700;
    font-style: italic;
    src: url(fonts/fedra_serif/WF-006389-009281-000055.woff) format("woff")
}